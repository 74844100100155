import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

const FormSent = () => (
    <Layout>
        <SEO title="Submitted" />
        <div className="not-found">
            <div className="not-found-text">Your message was recorded</div>
            <div className="not-found-link">
                <a href="/">Let's take you back to the homepage</a>
            </div>
        </div>
    </Layout>
);

export default FormSent;
